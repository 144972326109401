import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { routes } from "../src/Routes/Routes";
import './assests/css/style1.css'
import './assests/css/style2.css'
import './assests/css/style3.css'
import './assests/css/style4.css'
import './assests/css/style5.css'
import './assests/css/style6.css'
import './assests/css/style7.css'
import './assests/css/nav.css'
import './assests/css/style8.css'
import './assests/css/mamoon.css'



class App extends Component {

  componentDidMount() {
  }

  render() {
    return (
      <BrowserRouter>
          <Switch>
            {
              routes.map((obj) => {
                return (
                  <Route key={obj.id} path={obj.path} component={obj.component} />
                
                )
              })
            }
            <Redirect from="/" to="/tv"/> 
          </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
