import Home from "../Containers/Home/Home";

export const routes = [
       {
        id:1,
        path: "/",
        component: Home
    },
    {
        id:2,
        path: "/voice-phone",
        component: Home
    },
    {
        id:3,
        path: "/deals",
        component: Home
    },
    {
        id:4,
        path: "/phone",
        component: Home
    },
    {
        id:5,
        path: "/internet",
        component: Home
    },
    {
        id:6,
        path: "/tv",
        component: Home
    },
] 